import cn from 'classnames'
import React from 'react'
import Link from '../common/util/input/Link'

const CollectionTitle = ({
    author,
    collectionName,
    collectionImage,
    collectionThumbnail,
    verified,
}) => {
    const image =
        collectionThumbnail && process.env.NEXT_PUBLIC_TESTNET !== 'TRUE'
            ? collectionThumbnail
            : collectionImage

    return (
        <Link href={'/collection/' + author}>
            <div
                className={cn(
                    'relative flex justify-left text-black dark:!text-white cursor-pointer pb-2',
                )}
            >
                <div
                    className={cn(
                        'flex w-auto rounded-xl 4xl:rounded-2xl',
                        { 'border-primaryt': verified },
                        { 'border-neutralt': !verified },
                    )}
                >
                    {image && (
                        <div
                            className={cn(
                                'flex w-8 mt-a-pix ml-a-pix',
                                'md:w-7 xl:w-8 4xl:w-10 h-8 md:h-7 xl:h-8 4xl:h-10',
                                'overflow-hidden',
                            )}
                        >
                            <img className={cn('m-auto')} src={image} alt="" />
                        </div>
                    )}
                    <div className={cn('flex mx-2 my-auto h-full')}>
                        <p
                            className={cn(
                                'flex font-bold md:font-semibold m-auto h-full text-lg md:text-sm xl:text-lg 4xl:text-xl',
                                'leading-8 md:leading-7 xl:leading-8 4xl:leading-10',
                            )}
                        >
                            {collectionName}
                        </p>
                    </div>
                    {verified && (
                        <div
                            className={cn(
                                'w-7 md:w-5 xl:w-7 h-6 md:h-5 xl:h-6 4xl:h-7 4xl:w-8 my-auto',
                            )}
                        >
                            <img
                                src={'/verified.svg'}
                                alt="verified"
                                width={24}
                                height={24}
                            />
                        </div>
                    )}
                </div>
            </div>
        </Link>
    )
}

export default CollectionTitle
