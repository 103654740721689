import React, { useContext, useEffect, useState } from 'react'
import { useTranslation } from 'react-i18next'
import { getAccountInfo } from '../helpers/Api'
import { WalletRow } from '../wallet/WalletRow'
import { Context } from '../waxplorer/Waxplorer'

export const NFTHiveDropsCPUUsage = ({ accountName }) => {
    const { t } = useTranslation('common')

    const [account, setAccount] = useState({
        netWeight: 0,
        cpuWeight: 0,
        ramBytes: 0,
        cpu: {
            used: 0,
            available: 0,
            max: 0,
        },
    })

    const parseAccount = (res) => {
        if (res && res.status === 200) {
            const data = res['data']

            const resources = data['self_delegated_bandwidth']

            const balance = data['core_liquid_balance']
                ? data['core_liquid_balance'].replace(' WAX', '')
                : '0'

            const cpu = data['cpu_limit']

            setAccount({
                cpuWeight: parseFloat(
                    resources && resources['cpu_weight']
                        ? resources['cpu_weight'].replace(' WAX', '')
                        : 0,
                ),
                cpu: cpu,
                balance: balance,
            })
        }
        setIsLoading(false)
    }

    const [state] = useContext(Context)
    const [isLoading, setIsLoading] = useState(false)

    const stakeCpuNet = () => {}

    useEffect(() => {
        setIsLoading(true)
        getAccountInfo(accountName, state).then(parseAccount)
    }, [accountName])

    useEffect(() => {}, [account])

    return (
        <div>
            {account ? (
                <WalletRow
                    title={'CPU Usage: '}
                    progressBar={true}
                    progress={
                        account.cpu.max > 0
                            ? account.cpu.used / account.cpu.max
                            : 0
                    }
                    color={'bg-card2'}
                    name={'wallet_cpu_usage'}
                    useLink={`/cpu?account=${accountName}`}
                    balance={account.balance}
                    buttonText={t('navigation.rent_cpu')}
                    externalLoading={isLoading}
                />
            ) : (
                ''
            )}
        </div>
    )
}
