import { LinearProgress, TableCell, TableRow } from '@mui/material'
import cn from 'classnames'
import React, { useContext, useState } from 'react'
import { useTranslation } from 'react-i18next'

import ErrorNote from '../common/util/ErrorNote'
import Link from '../common/util/input/Link'
import MainButton from '../common/util/input/MainButton'
import { formatPercentage } from '../helpers/FormatLinks'
import { Context } from '../waxplorer/Waxplorer'

export const WalletRow = ({
    title,
    tokenIcon,
    content,
    token,
    balance,
    hasClaim,
    staked,
    stakable,
    claimFunc,
    updateFunc,
    progressBar,
    progress,
    color,
    buttonText,
    externalFunc,
    externalLoading,
    useLink,
    name,
}) => {
    const tdClassFirst = cn('relative text-left h-16')
    const tdClassNext = cn(
        'relative text-right h-16 overflow-x-auto dark:text-white text-black my-auto ml-auto',
    )
    const tdClassLast = cn('relative text-right h-16 ml-auto')

    const [error, setError] = useState(null)
    const [isLoading, setIsLoading] = useState(false)

    const { t } = useTranslation('common')

    const dismissError = () => {
        setError(null)
    }

    const [, dispatch] = useContext(Context)

    const claim = async () => {
        setIsLoading(true)
        try {
            claimFunc && (await claimFunc())
        } catch (e) {
            console.error(e)
            setError(e.message)
            setIsLoading(false)
        } finally {
            setTimeout(function () {
                updateFunc && updateFunc()
                setIsLoading(false)
            }, 3000)
        }
    }

    const transfer = async () => {
        setIsLoading(true)
        dispatch({ type: 'SET_WAX_BALANCE', payload: balance })
        dispatch({
            type: 'SET_ACTION',
            payload:
                token === 'HONEY'
                    ? 'transfer_honey'
                    : token === 'TLM'
                    ? 'transfer_tlm'
                    : token === 'AETHER'
                    ? 'transfer_aether'
                    : 'transfer_wax',
        })
        dispatch({
            type: 'SET_CALLBACK',
            payload: (transferInfo) => handleTransfer(transferInfo),
        })
        dispatch({ type: 'SET_BALANCE_CHANGED', payload: true })
    }

    const unstakeHoney = async () => {
        setIsLoading(true)
        dispatch({ type: 'SET_WAX_BALANCE', payload: balance })
        dispatch({ type: 'SET_ACTION', payload: 'unstake_honey' })
        dispatch({
            type: 'SET_CALLBACK',
            payload: (transferInfo) => handleTransfer(transferInfo),
        })
        dispatch({ type: 'SET_BALANCE_CHANGED', payload: true })
    }

    const stake = async () => {
        setIsLoading(true)
        dispatch({ type: 'SET_WAX_BALANCE', payload: balance })
        dispatch({
            type: 'SET_ACTION',
            payload: token === 'HONEY' ? 'stake_honey' : '',
        })
        dispatch({
            type: 'SET_CALLBACK',
            payload: (transferInfo) => handleTransfer(transferInfo),
        })
        dispatch({ type: 'SET_BALANCE_CHANGED', payload: true })
    }

    const handleTransfer = (transferInfo) => {
        const wasTransferred = transferInfo['transferred']
        const error = transferInfo['error']

        if (wasTransferred) {
            setTimeout(function () {
                updateFunc && updateFunc()
                setIsLoading(false)
            }, 3000)
        } else {
            setIsLoading(false)
        }

        if (error) {
            setIsLoading(false)
        }
        setError(error)
    }

    const customButton = (
        <div className={'w-32 ml-auto'}>
            <MainButton
                className="relative top-0 left-0 m-auto"
                onClick={externalFunc}
                disabled={isLoading}
                fullWidth
            >
                {buttonText}
            </MainButton>
        </div>
    )

    const transferButton = (
        <div className={'w-32 ml-auto'}>
            <MainButton
                className="relative top-0 left-0 m-auto"
                onClick={transfer}
                disabled={isLoading}
                fullWidth
            >
                {t('asset.transfer')}
            </MainButton>
        </div>
    )

    const stakeButton = (
        <div className={'w-32 mt-2 ml-auto'}>
            <MainButton
                className="relative top-0 left-0 m-auto"
                onClick={stake}
                disabled={isLoading}
                fullWidth
            >
                {t('asset.stake')}
            </MainButton>
        </div>
    )

    const claimButton = (
        <div className={'w-32 mt-2 ml-auto'}>
            <MainButton
                className="relative top-0 left-0 m-auto"
                onClick={claim}
                disabled={isLoading}
                fullWidth
            >
                {buttonText ? buttonText : t('asset.claim')}
            </MainButton>
        </div>
    )

    const unstakeButton = (
        <div className={'w-32 mt-2 ml-auto'}>
            <MainButton
                className="relative top-0 left-0 m-auto"
                onClick={unstakeHoney}
                disabled={isLoading}
                fullWidth
            >
                {t('asset.unstake')}
            </MainButton>
        </div>
    )

    const transferable = token && balance

    const unstakeable = buttonText && balance && staked

    const claimable = hasClaim && balance

    const ProgressBar = ({ progressPercentage, color }) => {
        return (
            <div className={'flex h-4'}>
                <div className="h-auto w-28 ml-auto bg-zinc-200 dark:!bg-neutral relative flex rounded overflow-hidden">
                    <div
                        style={{ width: `${progressPercentage * 100}%` }}
                        className={cn('h-auto leading-4 text-black', color)}
                    >
                        <div className={'px-1'}>
                            {formatPercentage(progressPercentage)}
                        </div>
                    </div>
                </div>
            </div>
        )
    }

    return (
        <TableRow
            sx={{ '&:last-child td, &:last-child th relative': { border: 0 } }}
            key={name}
        >
            <TableCell className={tdClassFirst}>
                <div className={'flex h-11'}>
                    {tokenIcon && (
                        <img
                            className={'w-6 h-6 my-auto mx-2'}
                            src={tokenIcon}
                            alt={'icon'}
                        />
                    )}
                    <b className={'my-auto'}>{title}</b>
                </div>
                {error && <ErrorNote onClick={dismissError} error={error} />}
            </TableCell>
            <TableCell className={progressBar ? 'my-auto' : tdClassNext}>
                {isLoading || externalLoading ? (
                    <div className={'w-36'}>
                        <LinearProgress />
                    </div>
                ) : progressBar ? (
                    <ProgressBar progressPercentage={progress} color={color} />
                ) : (
                    content
                )}
            </TableCell>
            <TableCell className={tdClassLast}>
                {transferable
                    ? transferButton
                    : claimable
                    ? claimButton
                    : unstakeable
                    ? unstakeButton
                    : ''}
                {externalFunc && buttonText ? customButton : ''}
                {useLink ? (
                    <Link href={useLink}>
                        <MainButton>{buttonText}</MainButton>
                    </Link>
                ) : (
                    ''
                )}
                {stakable ? stakeButton : ''}
                {staked ? unstakeButton : ''}
            </TableCell>
        </TableRow>
    )
}
