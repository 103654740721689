import React, { useEffect, useState } from 'react'
import { useTranslation } from 'react-i18next'
import { DropAlert } from '../drops/DropAlert'
import { get } from '../helpers/Api'
import { NFTHiveDropsCPUUsage } from '../nfthivedropscpuusage/NFTHiveDropsCPUUsage'

export const RenderingWarning = ({ open, contract, id }) => {
    const { t } = useTranslation('common')

    const [claimData, setClaimData] = useState({
        numClaimed: 0,
        numRendered: 0,
        numMinted: 0,
    })

    const { numClaimed, numRendered, numMinted } = claimData

    const parseClaimData = (res) => {
        setClaimData(res)
    }

    useEffect(() => {
        if (open) {
            get('get-minting-state/' + contract + '/' + id, 'api1').then(
                parseClaimData,
            )
        }
    }, [open])

    return (
        <DropAlert
            condition={open}
            severity="warning"
            title={t('general.attention')}
        >
            {t('drops.minting_warning')}
            <br />
            {t('drops.cpu_usage_of_x', {
                x: contract,
            })}
            : <br />
            <NFTHiveDropsCPUUsage accountName={contract} />
            {t('drops.report_warning')}{' '}
            <a
                className={'font-bold text-primary'}
                target={'_blank'}
                href={
                    'https://discord.com/channels/806257237705621514/905191562990977024'
                }
            >
                here
            </a>
            .
            <br />
            <br />
            {t('drops.currently_claimed')}: {numClaimed}
            <br />
            <br />
            {t('drops.currently_rendered')}: {numRendered}
            <br />
            <br />
            {t('drops.currently_minted')}: {numMinted}
        </DropAlert>
    )
}
